<template>
<!-- This example requires Tailwind CSS v2.0+ -->
  <div class="max-w-2xl mx-auto py-24 px-4 grid items-center grid-cols-1 gap-y-16 gap-x-8 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8 lg:grid-cols-2">
    <div>
      <h2 class="mb-8 text-xs font-semibold tracking-widest text-black uppercase title-font">AI-powered Video Analytics</h2>
      <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">{{ project.title }}</h2>
      <p class="mt-4 text-gray-500" v-for="description in project.description" :key="description" >{{ description}}</p>

      <dl class="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
        <div class="border-t border-gray-200 pt-4" v-for="feature in project.features" :key="feature.title">
          <dt class="font-medium text-gray-900">{{ feature.name }}</dt>
          <dd class="mt-2 text-sm text-gray-500">{{ feature.description }}</dd>
        </div>

      </dl>
    </div>
    <div class="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
      <img :src=" project.images[0] " alt="" class="bg-gray-100 rounded-lg">
      <img :src=" project.images[1] " alt="" class="bg-gray-100 rounded-lg">
      <img :src=" project.images[2] " alt="" class="bg-gray-100 rounded-lg">
      <img :src="project.images[3] " alt="" class="bg-gray-100 rounded-lg">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    project: Object
  },
  data() {
    return {
    }
  } 
}
</script>