<template>
  <div class="font-sans">
    <div id="content">
      <div>
        <main class="font-medium text-gray-200 bg-white">
          <!-- header block -->
          <div class="sticky top-0 z-50  bg-gradient-to-r from-blue-900 to-blue-800">
            <header
              class="
                flex
                justify-between
                max-w-screen-xl
                px-6
                py-4
                mx-auto
                lg:px-8
                xl:px-4
                lg:py-6
              "
            >
              <a href="#">
                <span class="sr-only">REDDOT landing page</span>
                <img
                  class="self-end h-10 p-1 justify-self-center"
                  src="/images/logo-reddot-white.svg"
                  alt=""
                />
              </a>
              <nav class="flex items-center space-x-4">
                <a
                  href="#contact"
                  class="flex items-center space-x-1 hover:text-white"
                >
                  <span>Contact us</span>
                </a>
                <!-- <a
                  href="#"
                  class="
                    inline-block
                    px-5
                    py-2
                    font-semibold
                    text-white
                    rounded-lg
                    bg-gradient-to-br
                    from-blue-600
                    to-blue-700
                    hover:from-blue-500
                    hover:to-blue-700
                  "
                  >Sign up</a
                > -->
              </nav>
            </header>
          </div>
          <!--/ header block -->

          <div
            class="py-12 md:py-24 bg-gradient-to-r from-blue-900 to-blue-800"
          >
            <div
              class="
                grid
                max-w-screen-xl
                px-6
                mx-auto
                lg:px-8
                xl:px-4
                md:grid-cols-4
                xl:grid-cols-5
                gap-x-12
                lg:gap-x-20
              "
            >
              <div
                class="self-center order-2 col-span-2 mt-12 md:order-1 md:mt-0"
              >
                <h1
                  class="
                    mb-2
                    text-3xl
                    font-bold
                    text-white
                    md:text-4xl
                    lg:text-5xl
                    md:mb-4
                    lg:mb-8
                  "
                >
                  Intelligent AI-powered video analytics
                </h1>
                <p
                  class="mb-6 text-lg text-gray-200 xl:text-xl lg:mb-8 xl:mb-10"
                >
                  Expand your business with AI capabilities. Enhance your
                  solutions with video analytics and start taking advantage of
                  cutting-edge technology from NVIDIA, Intel and Google.
                </p>
                <div class="flex mb-6 space-x-4">
                  <input
                    type="text"
                    placeholder="enter your email..."
                    class="
                      flex-1
                      px-4
                      py-4
                      leading-none
                      border border-gray-200
                      rounded-lg
                      focus:outline-none
                    "
                  />
                  <button
                    class="
                      inline-block
                      px-5
                      py-2
                      font-semibold
                      text-white
                      rounded-lg
                      focus:outline-none
                      bg-gradient-to-br
                      from-blue-600
                      to-blue-700
                      hover:from-blue-500 hover:to-blue-700
                    "
                  >
                    Get started
                  </button>
                </div>
                <p class="text-sm text-gray-400">Book a demo</p>
              </div>
              <div class="order-1 col-span-2 md:order-2 xl:col-span-3">
                <iframe
                  class="rounded-lg shadow-2xl"
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/PmBwOQzp6_s?controls=0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>

                <!--  <img
                  src="/images/hero-image-dark.png"
                  class="rounded-lg shadow-2xl"
                  alt=""
                /> -->
              </div>
            </div>
          </div>

          <!-- logo block -->
          <div class="bg-gray-900">
            <div class="py-12">
              <div
                class="
                  grid
                  max-w-screen-xl
                  grid-cols-2
                  col-gap-6
                  px-6
                  mx-auto
                  space-y-5
                  lg:px-8
                  xl:px-4
                  sm:grid-cols-3 sm:space-y-3
                  xl:grid-cols-5
                  opacity-95
                "
              >
                <img
                  class="self-end h-16 p-1 justify-self-center"
                  src="/images/logo-tandil.gif"
                  alt=""
                />
                <img
                  class="self-end h-12 p-1 justify-self-center"
                  src="/images/logo-unicen.png"
                  alt=""
                />
                <img
                  class="self-end h-12 p-1 justify-self-center"
                  src="/images/pladema-logo.png"
                  alt=""
                />
                <img
                  class="self-end h-12 p-1 justify-self-center"
                  src="/images/logo-cic.png"
                  alt=""
                />
                <img
                  class="self-end h-14 p-1 justify-self-center"
                  src="/images/logo-conicet.png"
                  alt=""
                />
                <!-- <img
                  class="self-end h-10 p-1 justify-self-center"
                  src="/images/feedback-logo-dark.svg"
                  alt=""
                /> -->
              </div>
            </div>
          </div>

          <!--/ logo block -->

          <!-- pricing block -->
          <!-- <div class="bg-gradient-to-b from-black to-gray-900"> -->
          <div class="text-black bg-gray-100 pt-16 lg:pt-32">
            <div
              class="
                max-w-screen-xl
                px-6
                pb-6
                mx-auto
                lg:px-8
                xl:px-4
                lg:pb-8
                xl:pb-12
              "
            >
              <div class="mb-6 text-center md:mb-8">
                <h2
                  class="
                    mb-2
                    text-3xl
                    font-bold
                    md:text-4xl
                    lg:text-5xl
                    md:mb-4
                  "
                >
                  Get a better understanding of everything you capture
                </h2>
                <p class="text-lg text-gray-600 xl:text-xl">
                  We offer high quality, innovative and state-of-the-art video
                  analytics.
                </p>
              </div>

              <div class="flex justify-center mb-8 md:mb-20 lg:mb-24">
                <nav
                  class="
                    inline-flex
                    overflow-hidden
                    text-sm
                    bg-blue-100
                    rounded-lg
                  "
                ><a href="#products">
                  <button
                    class="
                      px-6
                      py-3
                      font-bold
                      text-white
                      focus:outline-none
                      bg-gradient-to-br
                      from-blue-600
                      to-blue-700
                      hover:from-blue-500 hover:to-blue-700
                    "
                  >
                    Explore our solutions
                  </button>
                  </a>
                  <a href="#contact">
                  <button
                    class="
                      px-6
                      py-3
                      font-bold
                      text-blue-500
                      focus:outline-none
                      hover:bg-blue-50
                    "
                  >
                    Talk about new ideas
                  </button>
                  </a>
                </nav>
              </div>
            </div>
          </div>
          <!--/ pricing block -->
          
          <!-- PRODUCTS -->
          <div id="products" class="text-black">
            <projects-widget></projects-widget>
          </div>

          <div id="contact" class="">
            <section class="text-gray-800 body-font max-w-screen-xl mx-auto">
              <div
                class="container px-5 py-12 mx-auto flex flex-wrap items-center"
              >
                <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl"><span class="block">Ready to boost your idea?</span><span class="block text-blue-600">Book a demo</span></h2>
                <div
                  class="
                    lg:w-2/6
                    md:w-1/2
                    bg-white-100
                    rounded-lg
                    p-8
                    flex flex-col
                    md:ml-auto
                    w-full
                    mt-10
                    md:mt-0
                  "
                >
                  <div id="hubspotForm" v-once></div>
                </div>
              </div>
            </section>
          </div>

          <!-- footer block -->
          <footer class="py-12 text-gray-200 bg-gray-900 xl:pb-24">
            <div
              class="
                max-w-screen-xl
                px-6
                mx-auto
                mb-12
                lg:px-8
                xl:px-4
                lg:mb-16
              "
            >
              <img
                class="self-end h-10 p-1 justify-self-center"
                src="/images/logo-reddot-white.svg"
                alt=""
              />
            </div>
            <div
              class="
                grid
                max-w-screen-xl
                gap-6
                px-6
                mx-auto
                lg:px-8
                xl:px-4
                md:grid-cols-2
                lg:grid-cols-4 lg:gap-x-8
              "
            >
              <div>
                <h5 class="text-xl font-bold text-gray-300">Product</h5>
                <nav class="mt-4">
                  <ul class="space-y-2">
                    <li>
                      <a href="#" class="text-base hover:text-gray-500"
                        >SmartCam</a
                      >
                    </li>
                    <li>
                      <a href="#" class="text-base hover:text-gray-500"
                        >Stream</a
                      >
                    </li>
                  </ul>
                </nav>
              </div>
              <div>
                <h5 class="text-xl font-bold text-gray-300">Services</h5>
                <nav class="mt-4">
                  <ul class="space-y-2">
                    <li>
                      <a href="#" class="text-base hover:text-gray-500"
                        >R&D + Innovation</a
                      >
                    </li>
                  </ul>
                </nav>
              </div>
              <div>
                <h5 class="text-xl font-bold text-gray-300">About us</h5>
                <nav class="mt-4">
                  <ul class="space-y-2">
                    <li>
                      <a href="#" class="text-base hover:text-gray-500"
                        >Company</a
                      >
                    </li>
                  </ul>
                </nav>
              </div>
              <div>
                <h5 class="text-xl font-bold text-gray-300">Legal</h5>
                <nav class="mt-4">
                  <ul class="space-y-2">
                    <li>
                      <a href="#" class="text-base hover:text-gray-500"
                        >Terms and conditions</a
                      >
                    </li>
                    <li>
                      <a href="#" class="text-base hover:text-gray-500"
                        >Security</a
                      >
                    </li>
                    <li>
                      <a href="#" class="text-base hover:text-gray-500"
                        >Privacy</a
                      >
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div
              class="
                flex flex-col
                items-center
                justify-between
                max-w-screen-xl
                px-6
                mx-auto
                mt-16
                space-y-4
                lg:px-8
                xl:px-4
                md:flex-row
                lg:mt-20
              "
            >
              <div
                class="space-y-4 text-sm text-center md:space-y-1 md:text-left"
              >
                <p>
                  &copy;2020-2021 REDDOT SAS. All rights reserved.
                </p>
              </div>
              <a
                href="#"
                class="
                  inline-block
                  px-5
                  py-4
                  font-semibold
                  text-white
                  rounded-lg
                  bg-gradient-to-br
                  from-blue-600
                  to-blue-700
                  hover:from-blue-500 hover:to-blue-700
                  lg:px-8
                  md:transform md:-translate-y-2
                  hidden
                "
                >Try our products</a
              >
            </div>
            <div
              class="
                flex flex-col
                items-center
                justify-between
                max-w-screen-xl
                px-6
                mx-auto
                mt-8
                space-y-4
                lg:px-8
                xl:px-4
                md:flex-row
                lg:mt-12
              "
            >
              <nav class="flex flex-wrap justify-center space-x-6">
                <a href="#" class="mb-2 text-sm hover:text-gray-700">Privacy</a>
                <a href="#" class="mb-2 text-sm hover:text-gray-700">Legal</a>
              </nav>
              <nav class="flex items-center space-x-2">
                <a href="#" class="text-gray-500 hover:text-gray-200">
                  <span class="sr-only">Facebook</span>
                  <svg
                    class="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
                <a href="#" class="text-gray-500 hover:text-gray-200">
                  <span class="sr-only">Instagram</span>
                  <svg
                    class="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
                <a href="#" class="text-gray-500 hover:text-gray-200">
                  <span class="sr-only">Twitter</span>
                  <svg
                    class="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                    />
                  </svg>
                </a>
              </nav>
            </div>
          </footer>
          <!--/ footer block -->
        </main>
      </div>
    </div>
  </div>
</template>

<script>

//import Navigation from "./components/ui/Navigation.vue";
//import Home from "./pages/Home.vue";
//import Footer from "./components/Footer.vue";c
import ProjectsWidget from "./components/ui/ProjectsWidget.vue";

export default {
  name: "App",
  components: {
    ProjectsWidget,
    //  Navigation,
    //  Home,
    //  Footer,
  },
  mounted() {
    const script = document.createElement("script");
    script.src="//js.hsforms.net/forms/shell.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "21136711",
          formId: "ac64dcfc-aa3e-4104-bb27-cbff93d41d06",
          target: "#hubspotForm"
        })
      }
    })
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;
  color: #2c3e50;
  */
}
</style>
