<template>
<div class="pt-16 lg:pt-32">
  <div class="pt-8 text-center">
    <h2 class="
                    mb-2
                    text-3xl
                    font-bold
                    md:text-4xl
                    lg:text-5xl
                    md:mb-4
                  "> Our Products</h2>
  </div>
  
  <project-card :project="projects[0]"></project-card>


</div>
  <!-- Smartcam Stream -->
  <div class="bg-gray-100">
    <div class="max-w-2xl mx-auto py-24 px-4 grid items-center grid-cols-1 gap-y-16 gap-x-8 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8 lg:grid-cols-2">
      <div>
        <h2 class="mb-8 text-xs font-semibold tracking-widest text-black uppercase title-font">SCALE YOUR INFRASTRUCTURE</h2>
        <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">{{ projects[1].title }}</h2>
        <p class="mt-4 text-gray-500" v-for="description in projects[1].description" :key="description" >{{ description}}</p>

        <dl class="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
          <div class="border-t border-gray-200 pt-4" v-for="feature in projects[1].features" :key="feature.title">
            <dt class="font-medium text-gray-900">{{ feature.name }}</dt>
            <dd class="mt-2 text-sm text-gray-500">{{ feature.description }}</dd>
          </div>

        </dl>
      </div>
      <div class="">
        <img :src=" projects[1].images[0] " alt="" class="bg-gray-100 rounded-lg">
      </div>
    </div>
  </div>
  


  

</template>

<script>

const projects = [
      {
        title: "Smartcam",
        description: [
          "Accelerate your video forensics tasks and optimize real time analysis. Smartcam offers you an integrated suite for processing, classification and analysis of both recorded and live video streams.",
  //    "Add value to any conventional video surveillance camera system by allowing you to automatically search, classify and recognize objects in video.",
          "It drastically reduces the time required to resolve security issues by extracting only the relevant information from video signals, optimizing the resources of the monitoring centers by reviewing hours of video in minutes."],
        category: "Product",
        url: "products/videoresumen",
        images: [
          "/images/videoresumen/videoresumen.png",
          "/images/videoresumen/filter-type.png",
          "/images/videoresumen/filter-color.png",
          "/images/videoresumen/digifort.png",

        ],
        features: [
          {
            name: "Summary Video",
            description: "Detected objects are placed together maximizing the amount of objects you can see at a time, while preserving a comprehensible scene."
          },
          {
            name: "DNNs",
            description: "In-house trained Deep Neural Networks provide powerful search filter, such as color, type, speed, direction and more."
          },
          {
            name: "Edge",
            description: "Take analysis and reporting to the Edge, where continuous active internet connection cannot be guaranteed."
          },
          {
            name:" Integrations",
            description: "Expand you current infrastructure capabilities by integrating your platform with SmartCam"
          }
        ]
      },
      {
         title: "Stream",
         description: [
          "Go beyond the limits of your infrastructure.",
          "",
        //  " Reach every single camera deployment from inside out. No port openings, just over the internet.",
  //    "Add value to any conventional video surveillance camera system by allowing you to automatically search, classify and recognize objects in video.",
          "Share any accesible IP camera recordings or live stream to the cloud."],
        category: "Product",
        url: "products/videoresumen",
        images: [
          "/images/stream/smartcam-stream.jpg",
        ],
        features: [
          {
            name: "Scalable Media Server",
            description: "We leverage Open Source Software solutions to provide scalable streaming services."
          },
          {
            name: "Record & Share",
            description: "The Stream App (AndroidTV) is meant to act as proxy between local infrastructure and the Cloud."
          },
          {
            name: "Managed",
            description: "A Cloud Administration Panel can trigger transmission or recording sync if allowed by each App sharing settings."
          },
          {
            name:"Limitless",
            description: "Expand you current infrastructure capabilities by integrating your platform with REDDOT Stream"
          }
        ]
      },
      {
        title: "SmartCam VideoResumen",
        category: "Product",
        url: "products/videoresumen",
        users: [
          {
            avatar: "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?auto=format&fit=facearea&facepad=2&w=48&h=48&q=80",
            name: "Juan"
          }
        ]
      },
    ];

  import ProjectCard from './ProjectSection.vue';

export default {
  components: {
    ProjectCard
  },
  data() {
    return {
      projects: projects
    }
  }
  
}
</script>